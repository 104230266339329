function fetchIPAddress() {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                const ipAddress = data.ip;
                // Update the input field with the fetched IP address
                document.querySelector('input[data-variable="ipAddress"]').value = ipAddress;
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
            });
    }
window.addEventListener('heyflow-init', (event) => {
    fetchIPAddress();
});